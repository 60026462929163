@import 'styles';

.header {
  background-color: $white;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--overall-padding-x);
  padding-right: var(--overall-padding-x);
  box-shadow: 0px 0px 6px 1px rgba($black, 0.15);

  .logo {
    height: 1.5rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.avatar-content {
  display: grid;
  place-items: center;
  gap: 0.5rem;
  max-width: 10rem;
  overflow: hidden;

  .image {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .name,
  .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  .email {
    opacity: 0.6;
  }

  .button {
    width: 100%;
  }
}
