@import 'styles';

.protected-container {
  display: flex;

  .component {
    padding: 1.25rem 1.5rem;
    flex: 1;
  }
}
