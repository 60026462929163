$white: #fff;
$black: #000;
$black-lf: #000126;
$error: #cc0000;
$fill-grey-light2: #d4d4d4;
$fill-primary-medium: #0277bd;
$fill-primary-blue: #0067e2;
$fill-primary-blue-2: #f2f8ff;
$fill-primary-green: #27ae60;
$fill-grey-medium: #676773;
$fill-primary-orange-1: #fe8710;
$fill-rusty-orange: #dd750e;
$secondary-bg-blue-2: #f6f7f9;
$secondary-blue-2: #cee4ff;
$secondary-yellow-2: #fff1cc;
$secondary-orange-2: #ffeedf;
$secondary-gray-3: #93939f;
$neutral-gray-5: #e5e5e5;
$neutral-gray-2: #626870;
$neutral-gray-1: #303c4a;
$neutral-black: #00142d;
$fill-primary-teal-3: #ebfdff;
$fill-primary-teal: #007180;
