@import 'styles';

.loader-wrapper {
  background-color: rgba($black, 0.6);
  position: fixed;
  inset: 0;
  z-index: 9999;
  cursor: not-allowed;
  display: grid;
  place-items: center;
}
