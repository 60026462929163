@import 'styles';

.sidebar-wrapper {
  height: calc(100vh - var(--header-height)) !important;
  background-color: $secondary-bg-blue-2 !important;
  position: sticky !important;
  top: 0 !important;

  .icon {
    padding-right: 1rem;
  }

  ul {
    background-color: $secondary-bg-blue-2;
  }
}
